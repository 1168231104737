import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import Header from "../components/header/header";
import CreateAccount from "../components/create_account/create_account";

function CreateAccountPage() {
  return (
    <ChakraProvider>
      <Header />
      <CreateAccount />
    </ChakraProvider>
  );
}

export default CreateAccountPage;
