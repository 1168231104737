import { Box } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import { OpaliteTextStandard, OpaliteTextTitle } from "../shared/opalite_text";
import { OpaliteInput } from "../shared/input";
import { OpaliteButtonStandard } from "../shared/opalite_buttons";
import * as EmailValidator from "email-validator";
import passwordValidator from "password-validator";
import { navigate } from "gatsby";
import { ApiContext, UserContext } from "../Root";
import { ApiClient } from "../../services/api";
import { AccountCreateRequest } from "../../services/api_types";

interface CreateAccountProps {}

const CreateAccount: React.FC<CreateAccountProps> = (props) => {
  const api = useContext(ApiContext);
  const user = useContext(UserContext);
  const schema = new passwordValidator();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [inviteCode, setInviteCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validateInformation = () => {
    const validationArray = [];
    schema.is().min(8);
    if (firstName.length < 1) {
      validationArray.push("first name");
    }
    if (lastName.length < 1) {
      validationArray.push("last name");
    }
    if (username.length < 1) {
      validationArray.push("username");
    }
    if (!EmailValidator.validate(email)) {
      validationArray.push("email");
    }
    if (!schema.validate(password)) {
      validationArray.push("password");
    }
    console.log(validationArray);
    if (validationArray.length >= 1) {
      generateErrorMessage(validationArray);
      return false;
    } else {
      return true;
    }
  };

  const generateErrorMessage = (errorArray) => {
    if (errorArray.length === 1) {
      setErrorMessage(`Please enter a valid ${errorArray[0]}.`);
    } else if (errorArray.length === 2) {
      setErrorMessage(
        `Please enter a valid ${errorArray[0]}, and ${errorArray[1]}.`
      );
    } else if (errorArray.length === 3) {
      setErrorMessage(
        `Please enter a valid ${errorArray[0]}, ${errorArray[1]}, and ${errorArray[2]}.`
      );
    } else if (errorArray.length === 4) {
      setErrorMessage(
        `Please enter a valid ${errorArray[0]}, ${errorArray[1]}, ${errorArray[2]}, and ${errorArray[3]}.`
      );
    } else if (errorArray.length === 5) {
      setErrorMessage(
        `Please enter a valid ${errorArray[0]}, ${errorArray[1]}, ${errorArray[2]}, ${errorArray[3]}, and ${errorArray[4]}.`
      );
    }
  };

  const handleButtonSubmit = async () => {
    if (validateInformation()) {
      const request: AccountCreateRequest = {
        first_name: firstName,
        last_name: lastName,
        username: username,
        email: email,
        account_password: password,
      };
      if (!!inviteCode) {
        request.invite_code = inviteCode;
      }
      const accountCreateResponse = await api.client.createAccount(request);
      if (!accountCreateResponse) {
        // TODO propagate error to client
        console.log("error creating account");
        return;
      }
      if (typeof window !== "undefined") {
        window.localStorage.setItem("token", accountCreateResponse.data.token);
      }
      const client = new ApiClient(accountCreateResponse.data.token);
      api.setClient(client);
      const accountData = await client.getAccount();
      if (!accountData) {
        console.log("error with create account response");
        return;
      }
      const userData = {
        account_id: accountData.account_id,
        email: accountData.email,
        username: accountData.username,
        first_name: accountData.first_name,
        last_name: accountData.last_name,
        is_influencer: accountData.is_influencer,
      };
      if (typeof window !== "undefined") {
        window.localStorage.setItem("user", JSON.stringify(userData));
      }
      user.setUser(userData);
      console.log("set user to", userData);
      if (!!accountCreateResponse.data.influencer_id) {
        navigate("/verify_socials");
      }
      navigate("/all_nft");
    }
  };

  return (
    <Box height="100vh" backgroundColor={COLORS_PRIMARY.BACKGROUND}>
      <Box
        position="fixed"
        right={{ base: "unset", md: "2rem" }}
        bottom={{ base: "2rem", md: "50%" }}
        top={{ base: "unset", md: "30%" }}
        width={{ base: "80%", md: "20rem" }}
        marginInline="10%"
      >
        <OpaliteTextTitle fontSize="1.75rem">
          Create Your Account
        </OpaliteTextTitle>
        <OpaliteTextStandard
          marginTop="1rem"
          color="red"
          display={errorMessage.length ? "flex" : "none"}
        >
          {errorMessage}
        </OpaliteTextStandard>
        <OpaliteInput
          setText={setFirstName}
          marginTop="1rem"
          placeholder="First Name"
        />
        <OpaliteInput
          setText={setLastName}
          marginTop="1rem"
          placeholder="Last Name"
        />
        <OpaliteInput
          setText={setUsername}
          marginTop="1rem"
          placeholder="Username"
        />
        <OpaliteInput setText={setEmail} marginTop="1rem" placeholder="Email" />
        <OpaliteInput
          setText={setPassword}
          marginTop="1rem"
          placeholder="Password"
          type="password"
        />
        <OpaliteInput
          setText={setInviteCode}
          marginTop="1rem"
          placeholder="Invite Code - optional"
        />
        <OpaliteTextStandard mt="1rem">
          <OpaliteTextStandard
            marginBottom="1rem"
            color="red"
            display={errorMessage.length ? "flex" : "none"}
          >
            {errorMessage}
          </OpaliteTextStandard>
          <OpaliteButtonStandard
            onClick={handleButtonSubmit}
            marginBottom="1rem"
            width="100%"
          >
            Create Account
          </OpaliteButtonStandard>
          Already have an account? Click{" "}
          <a
            onClick={() => navigate("/login")}
            style={{
              color: COLORS_PRIMARY.TEXT_LINK,
              textDecoration: "underline",
            }}
          >
            here
          </a>
          .
        </OpaliteTextStandard>
      </Box>
    </Box>
  );
};

export default CreateAccount;
